import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Royalty Vision Construction Company </p>
    </div>
  );
};

export default Footer;
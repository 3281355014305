import React, { useState } from "react";
import "./Projects.css";
import one from "../../Images/Projects/1.png";
import two from "../../Images/Projects/2.png";
import three from "../../Images/Projects/3.png";
import four from "../../Images/Projects/4.png";
import five from "../../Images/Projects/5.png";
import six from "../../Images/Projects/6.png";
import seven from "../../Images/Projects/7.png";
import eight from "../../Images/Projects/8.png";
import nine from "../../Images/Projects/9.png";
import Masonry from "react-responsive-masonry";

const Projects = () => {
   const images = [
      {
         img: one,
         style: { width: "100%", height: "23%", display: "block" },
      },
      {
         img: two,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: three,
         style: { width: "100%", height: "95%", display: "block" },
      },
      {
         img: four,
         style: { width: "100%", height: "80%", display: "block" },
      },
      {
         img: five,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: six,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: seven,
         style: { width: "100%", height: "15%", display: "block" },
      },
      {
         img: eight,
         style: { width: "100%", height: "100%", display: "block" },
      },
      {
         img: nine,
         style: { width: "100%", height: "60%", display: "block" },
      },
   ];
   const [showImg, setShowImg] = useState(false);
   const [viewedImg, setViewedImg] = useState();
   const viewImg = (image) => {
      setViewedImg(image);
   };
   return (
      <div className="Projects" id="projects">
         <h2>Projects</h2>
         <div className="collage" style={showImg ? { opacity: "20%" } : null}>
            <Masonry columnsCount={3} gutter="1em">
               {images.map((image, i) => (
                  <img
                     key={i}
                     src={image.img}
                     style={image.style}
                     onClick={() => {
                        setShowImg(true);
                        viewImg(image.img);
                     }}
                  />
               ))}
            </Masonry>
         </div>
         <div
            className="viewImg"
            style={showImg ? { display: "block" } : { display: "none" }}
         >
            <div className="photo">
               <img src={viewedImg} alt="" />
            </div>
            <button onClick={() => setShowImg(false)}>
               <p>close</p>
            </button>
         </div>
      </div>
   );
};

export default Projects;
